@keyframes mlnpulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  70% {
    transform: scale(1); }
  100% {
    transform: scale(1); } }

.footer {
  background-image: linear-gradient(to top, #dfe7e9, #ffffff); }

.footer-logo {
  width: 131px;
  height: 43px;
  object-fit: contain; }

.social-icon {
  text-align: center;
  fill: #981b3b;
  color: #981b3b;
  font-size: 30px;
  margin: auto 12px;
  max-height: 30px; }
  .social-icon > * {
    height: 30px; }

.social-icon:first-child {
  margin-left: auto; }

.social-icon:last-child {
  margin-right: auto; }

.footer-menu a,
.footer-menu .footer-link {
  display: block;
  text-decoration: none;
  margin: 8px auto !important;
  font-size: 16px;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  color: #981b3b;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  cursor: pointer; }

.footer-menu .title {
  font-size: 14px; }

.footer-copyright {
  font-family: Quicksand;
  font-size: 12px;
  font-weight: bolder;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.3px;
  text-align: center;
  color: #5c1b3b;
  text-decoration: none; }

.footer-social .social-icon {
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s; }
  .footer-social .social-icon:hover.fb {
    color: #3b5998; }
  .footer-social .social-icon:hover.insta {
    color: #fccc63; }
  .footer-social .social-icon:hover.linked {
    color: #0077b5; }
  .footer-social .social-icon:hover.tw {
    color: #38a1f3; }

@media (min-width: 768px) {
  .footer-logo {
    width: 160px;
    max-width: 160px;
    max-height: auto; }
  .footer-logo-brand {
    width: 250px;
    max-width: unset;
    height: auto; }
  .footer-columns {
    max-width: 900px;
    margin: auto; } }
